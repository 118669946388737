(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('game')
        .constant('VERSION', "2.187.0+EMOTION.front")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('petitionURL', "https://service.firewall-caixabank.aiwin.co/")
        .constant('contentURL', "https://firewall-caixabank.aiwin.co/content/")
        .constant('mediaURL', "https://firewall-caixabank.aiwin.co/content/")
        .constant('staticURL', "https://firewall-caixabank.aiwin.co/app/")
        .constant('config', {
	"mainButton": false,
	"devMode": false,
	"loglevel": "error",
	"login": {
		"type": "form"
	}
})
        .constant('modules', {
	"common": "../../common/"
})
        .constant('tenant', "firewall-caixabank")
;
})();
